import { ZITADEL_AUTHORITY, ZITADEL_CLIENT_ID, ZITADEL_POST_LOGOUT, ZITADEL_SCOPE, ZITADEL_ORG_ID } from './env';
import { WebStorageStateStore, InMemoryWebStorage, UserManager } from 'oidc-client-ts';

const zitadelConfig = {
  authority: ZITADEL_AUTHORITY,
  post_logout_redirect_uri: ZITADEL_POST_LOGOUT || `${window.location.origin}`,
  client_id: ZITADEL_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback`,
  scope: `${ZITADEL_SCOPE || 'openid profile email'}${
    ZITADEL_ORG_ID ? ` urn:zitadel:iam:org:id:${ZITADEL_ORG_ID}` : ''
  }`,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
};

const createZitadelClient = () => {
  const authConfig = {
    response_type: 'code',
    response_mode: 'query',
  };

  const userManager = new UserManager({
    ...authConfig,
    ...zitadelConfig,
    loadUserInfo: true,
  });

  const authorize = () => {
    return userManager.signinRedirect({
      state: {
        previous_page: window.location.pathname + window.location.search,
      },
    });
  };

  const signout = () => {
    return userManager.signoutRedirect();
  };

  const oidc = {
    authorize,
    signout,
    userManager,
  };

  return oidc;
};

export default createZitadelClient;
