const app = (window && window.app) || {};
const env = (window && window.env) || {};

// App configuration
export const MODULES = env.MODULES;
export const BACKEND_HOST = env.BACKEND_HOST || app.BACKEND_HOST;
export const SERVER_HOST = env.SERVER_HOST;

// Mapbox configuration
export const MAPBOX_TOKEN = env.MAPBOX_TOKEN || app.MAPBOX_TOKEN;
export const MAPBOX_MAP_STYLE = env.MAPBOX_MAP_STYLE || app.MAPBOX_MAP_STYLE;

// Datalake configuration
export const DATALAKE_GRAPHQL_HOST = env.DATALAKE_GRAPHQL_HOST;
export const DATALAKE_API_HOST = env.DATALAKE_API_HOST;

// AD Configuration
export const AD_AUTHORITY = env.AD_AUTHORITY || app.AD_AUTHORITY;
export const AD_CLIENT_ID = env.AD_CLIENT_ID || app.AD_CLIENT_ID;

// Zitadel configuration
export const ZITADEL_AUTHORITY = env.ZITADEL_AUTHORITY;
export const ZITADEL_POST_LOGOUT = env.ZITADEL_POST_LOGOUT;
export const ZITADEL_CLIENT_ID = env.ZITADEL_CLIENT_ID;
export const ZITADEL_SCOPE = env.ZITADEL_SCOPE;
export const ZITADEL_ORG_ID = env.ZITADEL_ORG_ID;

// API Urls
export const CONFIGURATION_API_PATH = env.CONFIGURATION_API_PATH;
export const RESOURCES_API_PATH = env.RESOURCES_API_PATH;
export const LOGIN_API_PATH = env.LOGIN_API_PATH;
export const INSIGHTS_API_PATH = env.INSIGHTS_API_PATH;
export const SEC_ADMIN_API_URL = env.SEC_ADMIN_API_URL;
